import React, { useState } from "react";
import "../styles/Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="header relative flex items-center justify-between text-xl px-6 py-4 bg-[#1e191b] text-white">
      <div className="logo font-bold text-2xl">GIFTS-2024</div>

      {/* Mobile Menu Button */}
      <button
        className="lg:hidden text-white p-0"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
          />
        </svg>
      </button>

      {/* Centered Navigation Links */}
      <div className="nav-center hidden lg:flex lg:space-x-6">
        <a href="#home" className="py-2">
          HOME
        </a>
        <a href="#about" className="py-2">
          ABOUT
        </a>
        <a href="#agenda" className="py-2">
          AGENDA
        </a>
        <a href="#location" className="py-2">
          LOCATION
        </a>
      </div>

      {/* Register Button (Desktop) */}
      <div className="hidden lg:block">
        <a
          href="https://www.yesplis.com/event/gifts-women-in-stream"
          target="_blank"
          className="buy-ticket rounded-lg transition-all duration-500 ease-in-out bg-[#ff914d] hover:bg-[#ff5722] hover:text-white cursor-pointer text-black font-bold py-2 px-6 focus:outline-none focus:shadow-outline"
          rel="noreferrer"
        >
          REGISTER
        </a>
      </div>

      {/* Dropdown Menu (Mobile) */}
      <div
        className={`absolute top-full left-0 w-full bg-[#1e191b] flex flex-col items-center pt-0 pb-6 space-y-4 lg:hidden transition-all duration-500 ease-in-out ${
          isOpen
            ? "max-h-[500px] opacity-100"
            : "max-h-0 opacity-0 overflow-hidden"
        }`}
      >
        <a href="#home" className="w-full text-center py-2">
          HOME
        </a>
        <a href="#about" className="w-full text-center py-2">
          ABOUT
        </a>
        <a href="#agenda" className="w-full text-center py-2">
          AGENDA
        </a>
        <a href="#location" className="w-full text-center py-2">
          LOCATION
        </a>
        <a
          href="https://www.yesplis.com/event/gifts-women-in-stream"
          target="_blank"
          className="buy-ticket rounded-lg transition-all duration-500 ease-in-out bg-[#ff914d] hover:bg-[#ff5722] hover:text-white cursor-pointer text-black font-bold py-2 px-6 focus:outline-none focus:shadow-outline"
          rel="noreferrer"
        >
          REGISTER
        </a>
      </div>
    </header>
  );
};

export default Header;
