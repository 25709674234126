import React from "react";
import "../styles/HeroSection.css";
import { motion } from "framer-motion";
import GIFTS2024 from "../images/gifts-2024.png";
import UGM from "../images/logo/Logo Horizontal Stack-Up.png";
import PrimeStep from "../images/logo/PrimeStep.png";
import TechnoPark from "../images/logo/UGM Technopark.png";
import NUS from "../images/logo/NUS Enterprise.jpg";
import block71 from "../images/logo/Block 71-Innovation_Black.png";
import tsinghua from "../images/logo/Tsinghua University.png";
import unity from "../images/logo/United in Diversity.png";

const HeroSection = () => {
  return (
    <motion.section
      className="hero-section flex flex-col items-center text-center bg-cover bg-center py-10 md:py-20 px-4"
      id="home"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 1, duration: 1.5 }}
    >
      <div className="hero-content max-w-screen-lg w-full">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{}}
        >
          {/* Logos Row */}
          <div className="logos-row flex flex-wrap justify-center items-center bg-white p-2 rounded-xl md:space-x-4 md:space-y-0">
            {[UGM, PrimeStep, TechnoPark, NUS, block71, tsinghua, unity].map(
              (logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`Partner logo ${index + 1}`}
                  className="partner-logo max-w-[60px] lg:max-w-full object-contain"
                />
              )
            )}
          </div>

          <img
            className="pt-5 max-w-full h-auto mx-auto"
            src={GIFTS2024}
            alt="GIFTS 2024"
          />
        </motion.p>
        <p className="font-bold text-white mt-8 text-lg md:text-xl lg:text-2xl leading-tight mb-6">
          November 14<sup>th</sup> - 15<sup>th</sup> 2024
          <br />
          GIK UGM
        </p>
        <a
          href="https://www.yesplis.com/event/gifts-women-in-stream"
          target="_blank"
          className="rounded-lg transition-all duration-500 ease-in-out bg-[#ff914d] hover:bg-[#ff5722] hover:text-white cursor-pointer text-black font-bold py-2 text-xl lg:text-3xl px-6 focus:outline-none focus:shadow-outline"
          rel="noreferrer"
        >
          REGISTER
        </a>
        <a href="#about">
          <motion.div
            className="down-arrow flex justify-center pt-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, y: 15 }}
            transition={{
              delay: 2,
              repeat: Infinity,
              repeatType: "reverse",
              duration: 1.5,
            }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M12 5v14M5 12l7 7 7-7" />
            </svg>
          </motion.div>
        </a>
      </div>
    </motion.section>
  );
};

export default HeroSection;
