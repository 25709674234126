import React from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import Schedule from "./components/Schedule";
import Location from "./components/Location";
import Registration from "./components/Registration";
import Speakers from "./components/Speakers";
import Tracks from "./components/Tracks";
import FAQ from "./components/Faq";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <>
      <div className="App">
        <Header />
        <HeroSection />
        <AboutSection />
        <Schedule />
        <Location />
        {/* <Speakers /> */}
        {/* <Tracks /> */}
        {/* <FAQ /> */}
        <Footer />
      </div>
    </>
  );
}

export default App;
