import React from "react";
import "../styles/Footer.css";
import {
  FaTiktok,
  FaInstagram,
  FaXTwitter,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="footer">
      <p className="text-white font-bold">Follow GIK UGM</p>
      <div className="socials">
        <a
          href="https://www.tiktok.com/@gik.ugm"
          target="_blank"
          rel="noreferrer"
          aria-label="TikTok"
          className="social-icon"
        >
          <FaTiktok />
        </a>
        <a
          href="https://www.instagram.com/gik.ugm/"
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram"
          className="social-icon"
        >
          <FaInstagram />
        </a>
        <a
          href="https://x.com/GIK_UGM"
          target="_blank"
          rel="noreferrer"
          aria-label="X (Twitter)"
          className="social-icon"
        >
          <FaXTwitter />
        </a>
        <a
          href="https://www.youtube.com/@GelanggangInovasiKreativitas"
          target="_blank"
          rel="noreferrer"
          aria-label="YouTube"
          className="social-icon"
        >
          <FaYoutube />
        </a>
        <a
          href="https://www.linkedin.com/company/gik-ugm?originalSubdomain=id"
          target="_blank"
          rel="noreferrer"
          aria-label="LinkedIn"
          className="social-icon"
        >
          <FaLinkedin />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
