// src/components/AboutSection.js
import React from "react";
import styled from "styled-components";
import GIFTS from "../images/gifts.jpg";

const About = styled.section`
  padding: 20px;
  padding-top: 60px;
  background: #ffffff;
  color: #333;

  h2 {
    font-size: 2rem;
    color: #5555c6;
    text-align: center;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
  }

  @media (min-width: 768px) {
    padding: 40px;
    padding-top: 60px;
    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const AboutSection = () => (
  <About id="about">
    <h2 className="font-bold">What is GIFTS?</h2>
    <div className="pt-6 flex flex-col md:flex-row gap-6 md:gap-10 items-center">
      <p className="text-justify">
        GIFTS (Global Innovation Forum for Technology and Sustainability) is an
        innovative tri-party collaboration between Universitas Gadjah Mada,
        National University of Singapore, and Tsinghua University, designed to
        highlight the role of women in driving future technologies and
        innovations across various sectors. Set in the culturally rich city of
        Yogyakarta, GIFTS fosters an environment of collaboration, learning, and
        inspiration, where thought leaders and changemakers from around the
        world come together to tackle contemporary challenges.
        <br />
        <br />A key feature of GIFTS is a special segment “From Idea to Impact”
        —a 15-minute stitched video that shines a spotlight on 15 inspiring
        women from diverse STREAM fields (Science, Technology, Research,
        Engineering, Arts, and Mathematics). This segment is central to the
        event's agenda, offering a powerful visual narrative of women who are
        excelling in male-dominated industries. Each woman will share a
        one-minute story, where she speaks about her journey, the challenges she
        has faced as a woman in her field, and the contributions she has made to
        her industry or community.
        <br />
        <br />
        This particular segment within GIFTS features a powerful 15-minute
        stitched video, highlighting the stories of 15 women who are excelling
        in various STREAM fields. Each participant will deliver a one-minute
        narrative that encapsulates her journey, challenges, and key
        contributions. By weaving together these voices, the video aims to
        showcase the diverse paths women take to achieve success in STREAM,
        while also emphasizing the value of inclusivity and diversity in driving
        technological progress.
        <br />
        <br />
        The video serves as more than just a celebration of individual
        accomplishments; it is a call to action for greater gender
        representation within male-dominated sectors. It encourages the next
        generation of women leaders to step forward and empowers viewers to
        recognize the importance of diverse perspectives in fostering
        innovation. Through this dynamic visual narrative, GIFTS aims to inspire
        meaningful discussions, create new networking opportunities, and drive
        impactful solutions to contemporary global challenges.
      </p>
      {/* <img
        src={GIFTS}
        alt="GIFTS"
        className="w-full md:w-1/4 object-fit rounded-xl"
      /> */}
    </div>
  </About>
);

export default AboutSection;
