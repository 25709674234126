import React from "react";
import "../styles/Schedule.css";

const scheduleData = [
  {
    day: "Day 1 - 14 Nov 2024",
    events: [
      {
        time: "9:00 AM - 9:30 AM",
        venue: "Main Room (Student Center)",
        activity: "Opening Ceremony",
        topic: "Performing Arts, Opening Remarks",
      },
      {
        time: "9:30 AM - 9:45 AM",
        venue: "Main Room (Student Center)",
        activity: "Plenary Session",
        topic: "From Idea to Impact",
      },
      {
        time: "9:45 AM - 10:40 AM",
        venue: "Main Room (Student Center)",
        activity: "Plenary Session",
        topic: "Women Leadership: Leading with Purpose and Vision",
      },
      {
        time: "11:00 AM - 12:15 PM",
        venue: "Main Room (Student Center)",
        activity: "Plenary Session",
        topic:
          "Technology Commercialisation - The Importance of Universities to Drive Innovation",
      },
      {
        time: "11:15 AM - 12:15 PM",
        venue: "Breakout Room 2 (C-04)",
        activity: "Panel Session",
        topic: "Art Meets Technology: The Fusion of Creativity and Innovation",
      },
      {
        time: "12:15 PM - 1:00 PM",
        venue: "Main Room (Student Center)",
        activity: "Lunch Session",
      },
      {
        time: "3:15 PM - 4:15 PM",
        venue: "Main Room (Student Center)",
        activity: "Public Session",
        topic: "Session with Prof. Mats Hanson, and other speakers",
      },
      {
        time: "1:30 PM - 3:30 PM",
        venue: "Breakout Room 1 (C-03)",
        activity: "Workshop",
        topic: "Tech Transfer I&E Workshop by NUS Enterprise",
      },
      {
        time: "1:45 PM - 2:45 PM",
        venue: "Breakout Room 2 (C-04)",
        activity: "Panel Session",
        topic: "Nature & Sustainability: Innovating for a Sustainable Future",
      },
      {
        time: "2:45 PM - 3:45 PM",
        venue: "Breakout Room 2 (C-04)",
        activity: "Workshop",
        topic: "tbc",
      },
      {
        time: "4:00 PM - 5:30 PM",
        venue: "Main Room (Student Center)",
        activity: "Pitching",
        topic: "Battle Pitching - 30 Innovator Under 30",
      },
      {
        time: "5:00 PM - 6:30 PM",
        venue: "Main Room (Student Center)",
        activity: "Entertainment",
        topic: "Women Awarding Night + Art Performance",
      },
      {
        time: "7:00 PM - 8:30 PM",
        venue: "Main Room (Student Center)",
        activity: "Courtesy Dinner",
      },
    ],
  },
  {
    day: "Day 2 - 15 Nov 2024",
    events: [
      {
        time: "9:00 AM - 10:00 AM",
        venue: "Main Room (Student Center)",
        activity: "Keynote Speech",
        topic: "Founder & Innovator Ecosystem Innovation",
      },
      {
        time: "10:00 AM - 11:30 AM",
        venue: "Main Room (Student Center)",
        activity: "Pitching",
        topic: "Battle Pitching - 30 Innovator Under 30",
      },
      {
        time: "9:30 AM - 10:30 AM",
        venue: "Breakout Room 1 (C-03)",
        activity: "Workshop",
        topic: "TBC",
      },
      {
        time: "9:00 AM - 12:00 PM",
        venue: "Breakout Room 2 (C-04)",
        activity: "Workshop",
        topic: "Preparing Universities for Innovation & Entrepreneurship",
      },
      {
        time: "12:00 PM - 1:00 PM",
        venue: "Main Room (Student Center)",
        activity: "Closing",
        topic: "Awarding Battle Pitching, Art Performance",
      },
      {
        time: "1:00 PM - 2:00 PM",
        venue: "Main Room (Student Center)",
        activity: "Lunch",
      },
    ],
  },
];

// Function to determine background color based on the day
const getBackgroundColor = (dayTitle) => {
  if (dayTitle.includes("Day 1")) return "bg-[#5555c6]";
  if (dayTitle.includes("Day 2")) return "bg-[#3eba90]";
  // Add more conditions if you have more days
  return "bg-gray-500"; // Default background color
};

const Schedule = () => {
  return (
    <div className="schedule" id="agenda">
      <div>
        <h1 className="font-bold text-center text-white text-4xl pt-8 lg:pt-10">
          Agenda
        </h1>
        {scheduleData.map((day, index) => (
          <div key={index} className="day-section">
            <h2 className={`day-title ${getBackgroundColor(day.day)}`}>
              {day.day}
            </h2>
            {day.events.map((event, idx) => (
              <div key={idx} className="event">
                <div className="event-time">{event.time}</div>
                <div className="event-details">
                  <div className="event-venue">{event.venue}</div>
                  <div className="event-activity">{event.activity}</div>
                  {event.topic && (
                    <div className="event-topic">{event.topic}</div>
                  )}
                  {event.details && (
                    <ul className="event-subdetails">
                      {event.details.map((detail, i) => (
                        <li key={i}>{detail}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Schedule;
