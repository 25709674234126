import React from "react";
import "../styles/Schedule.css";

const Location = () => {
  return (
    <div className="location py-10" id="location">
      <div className="flex flex-col lg:flex-row">
        <div className="w-[100%] lg:w-[65%] p-0 lg:pr-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.146885304162!2d110.37356057574985!3d-7.774245077125491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5910f39cb25f%3A0xaab7386fd1291305!2sGelanggang%20Inovasi%20dan%20Kreativitas%20UGM!5e0!3m2!1sid!2sid!4v1730434577092!5m2!1sid!2sid"
            style={{ border: 0 }}
            className="h-[400px] lg:h-[400px] w-full"
            width="100%"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="UGM Innovation and Creativity Arena Map"
          ></iframe>
        </div>
        <div className="p-4 lg:pl-4 text-justify flex-col align-middle inline-block my-auto w-100 lg:w-1/4">
          {/* Content for the right half of the page */}
          <p className="text-base lg:text-2xl font-bold">
            Gelanggang Inovasi dan Kreativitas
          </p>
          <p className="text-base lg:text-2xl font-bold">
            Universitas Gadjah Mada
          </p>
          <br />
          <p>
            69GG+7FR, Jl. Persatuan, Sendowo, Sinduadi, Kec. Depok, Kabupaten
            Sleman, Daerah Istimewa Yogyakarta
          </p>
          <a
            href="https://maps.app.goo.gl/SvgU4BT2MZC79zRX6"
            target="_blank"
            rel="noreferrer"
          >
            <button className="rounded-lg bg-[#5555c6] hover:bg-[#3eba90] mt-4 font-bold cursor-pointer">
              Get in There
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Location;
